'use client'
import SubscribeContainer from '@components/atoms/SubscribeContainer';
import Image from 'next/image';
import Link from 'next/link';
import styles from './styles.module.css';
import { useTranslations } from 'next-intl';

export default function Footer() {
  const t = useTranslations('common.footer');

  return (
    <section className='bg-secondary flex flex-col py-16 px-4 md::px-12 lg::px-24 mt-[25%] lg:mt-[15%]'>
      <div className='-translate-y-1/4 md:-translate-y-2/4 flex justify-center'>
        <SubscribeContainer />
      </div>
      <footer className={`grid gap-8 -mt-20  ${styles.gridColumns}`}>
        <div className='flex flex-col items-center'>
          <Image src='/img/logo-white.svg' alt='Logo' width={200} height={50} />
          <div className='flex mt-6'>
            <a
              href='https://www.facebook.com/keyrockliving/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0'
              target='_blank'
              className='mr-4'
            >
              <Image
                src='/img/facebook.svg'
                alt='facebook icon'
                width={20}
                height={20}
                style={{ filter: 'brightness(0) saturate(100%) invert(88%) sepia(43%) saturate(321%) hue-rotate(339deg) brightness(91%) contrast(91%)' }}
              />
            </a>
            <a
              href='https://www.instagram.com/keyrock_holiday_homes/'
              target='_blank'
            >
              <Image
                src='/img/instagram.svg'
                alt='instagram icon'
                width={20}
                height={20}
                style={{ filter: 'brightness(0) saturate(100%) invert(88%) sepia(43%) saturate(321%) hue-rotate(339deg) brightness(91%) contrast(91%)' }}
              />
            </a>
          </div>

          <p className='text-[20px] text-salmon mt-10 text-center sm:text-start'>
            {t('copyWrite')}
          </p>
        </div>

        <div className='grid grid-cols-1 sm:grid-cols-3 w-full'>
          <div className='flex flex-col items-center md:items-start mr-4'></div>

          <div className='flex flex-col items-center md:items-start mr-4'>
            <h3 className='text-salmon mb-4 font-[TradeGothicBold] text-[20px]'>
              {t('aboutUs')}
            </h3>
            <Link
              href='/terms-and-conditions'
              className='mb-3 text-[20px] text-salmon'
            >
              {t('terms')}
            </Link>
            <Link
              href='/privacy-policy'
              className='mb-3 text-[20px] text-salmon'
            >
              {t('privacy')}
            </Link>
            <Link
              href='/about-us'
              className='mb-3 text-[20px] text-salmon'
            >
              {t('ourStory')}
            </Link>
          </div>

          <div className='flex flex-col items-center md:items-start mt-8 md:mt-0'>
            <h3 className='text-salmon mb-4 font-[TradeGothicBold] text-[20px]'>
              {t('contactUs')}
            </h3>
            <Link href='/contact' className='mb-3 text-[20px] text-salmon'>
              {t('contact')}
            </Link>
            <div className='mb-1 text-[20px] text-salmon'>
              {t('customerCare')}
            </div>
            <div className='mb-3 text-[20px] text-white font-bold'>+971 52 116 3159</div>
            <div className='mb-1 text-[20px] text-salmon'>
              {t('email')}
            </div>
            <div className='mb-3 text-[20px] text-white font-bold'>office@keyrockliving.com</div>
            <Image
              src='/img/paymentGateways.webp'
              width={200}
              height={40}
              alt='Payment providers'
              className='md:mr-16 mt-10'
            />
          </div>
        </div>
      </footer>
    </section>
  );
}
