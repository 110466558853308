import { useTranslations } from "next-intl";

export default function DarkButton ({text, translation, onClickEvent, styles}: {text: string, translation: string, onClickEvent: () => void, styles?: string}) {
  const t = useTranslations(translation);

  return (
    <button 
      onClick={onClickEvent} 
      className={`${styles} text-white bg-primary rounded-[4px] text-[20px] py-4 px-6 w-fit`}
    >
      {t(text)}
    </button>
  )
}