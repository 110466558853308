'use client'

import { useTranslations } from "next-intl";
import Image from "next/image";
import { useState } from "react";
import DarkButton from "../DarkButton";
import { toast } from "react-toastify";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import useRequest from "@hooks/useRequest";

export default function SubscribeContainer () {
  const t = useTranslations('common.footer');
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();

  const toastConfig: any = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  }

  const { request: sendEmailAddress } = useRequest({
    url: '/newsletter',
    method: 'post',
    onSuccess: (response: any) => {
      if (response.data.data.subscribed === 'subscribed') {
        
        return toast.success(t('success'), toastConfig)
      } 

      if (response.data.data.subscribed === 'already subscribed') {
        toast.success(t('alreadySubscribed'), toastConfig)
      }
    },
    onError: (error: any) => {
      toast.error(t('wrong'), toastConfig)
    },
  });

  const handleSubmit = async () => {
    if(!email)
      return;

    const testEmailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const isEmailValid = testEmailFormat.test(email);
    
    if(!isEmailValid) {
      toast.error(t('formatEmail'), toastConfig)
      return;
    }
    
    let token = '';
    if (executeRecaptcha) {
      token = await executeRecaptcha('newsletter');
      setToken(token);
      await sendEmailAddress({ body: { email, token }})
    } else console.log('Execute recaptcha not yet available');
  }

  return (
    <div 
      className='bg-salmon p-6 rounded-[20px] w-full lg:w-3/5 gap-10'
      style={{ boxShadow: '0px 4px 16px 0px rgba(17, 34, 17, 0.05)' }}
    >
      <div>
        <h2 className='text-black text-start'>{t('newsletter')}</h2>
        <p className='mt-6 mb-2 text-[20px] font-bold'>{t('title')}</p>
        <p className='text-[20px] font-semibold'>{t('inspired')}</p>
        <div className='w-full flex-col sm:flex-row flex mt-4'>
          <input 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            placeholder={t('placeholder')}
            className='w-full px-6 py-4 text-black mr-4 rounded-[4px]'
          />
          <div className='mt-4 sm:mt-0'>
            <DarkButton 
              text={'subscribe'} 
              translation={'common.footer'} 
              onClickEvent={handleSubmit} 
              styles={'!w-full sm:w-fit'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}