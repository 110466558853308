import axios, { AxiosRequestConfig, Method } from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';

interface HookProps extends AxiosRequestConfig {
  method: Method;
  url?: string;
  queryParams?: object;
  body?: any;
  local?: boolean;
  onSuccessMessage?: string;
  hideErrorMessage?: boolean;
  onSuccess?: (data: any) => void;
  onError?: (error: any, data: any) => void;
}

interface RequestProps {
  url?: string;
  queryParams?: object;
  body?: any;
}

const useRequest = (hookProps: HookProps) => {
  const [pending, setPending] = useState(false);

  const request = async (props?: RequestProps) => {
    setPending(true);

    try {
      const response = await axios({
        baseURL: hookProps.local ? '' : process.env.NEXT_PUBLIC_API_URL,
        url: props?.url || hookProps.url,
        params: props?.queryParams || hookProps.queryParams,
        method: hookProps.method,
        data: props?.body || hookProps.body,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          'Content-Type':
            hookProps.headers?.['Content-Type'] || 'application/json'
        }
      });
      hookProps.onSuccess && hookProps.onSuccess(response);
      hookProps.onSuccessMessage &&
        toast(hookProps.onSuccessMessage, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
    } catch (error: import('axios').AxiosError | any) {
      hookProps.onError && hookProps.onError(error, error.response?.data);
      if(error.response?.data.error === 'Unauthorized') {
        localStorage.setItem('token', '');
      }
      setPending(false);
      return false;
    }

    setPending(false);
    return true;
  };

  return { request, pending };
};

export default useRequest;
